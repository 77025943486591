import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M2433 7193 c-83 -75 -263 -359 -263 -416 0 -7 -4 -17 -9 -23 -9 -10
-29 -76 -58 -189 -26 -107 -24 -408 4 -530 52 -223 150 -414 302 -587 18 -21
38 -38 45 -38 18 0 136 117 136 135 0 9 -18 36 -39 58 -111 118 -199 297 -246
499 -30 131 -14 432 26 510 5 10 9 24 9 33 0 22 72 172 113 237 20 31 59 84
87 117 27 33 50 66 50 74 0 12 -121 137 -133 137 -3 0 -14 -8 -24 -17z"/>
<path d="M4186 7143 c-36 -36 -66 -68 -66 -71 0 -3 24 -35 54 -71 80 -98 139
-196 176 -291 18 -47 36 -94 41 -105 4 -11 10 -33 12 -50 3 -16 11 -61 17
-100 15 -86 8 -283 -13 -375 -43 -183 -127 -348 -241 -475 -25 -27 -46 -53
-46 -57 0 -13 123 -138 136 -138 37 0 171 176 244 319 87 173 140 395 140 583
0 70 -20 227 -39 313 -20 88 -96 265 -151 350 -11 17 -24 39 -29 50 -5 11 -19
31 -31 45 -12 14 -38 45 -58 69 -49 58 -62 71 -72 71 -4 0 -38 -30 -74 -67z"/>
<path d="M3230 7183 c-130 -22 -330 -101 -330 -131 0 -18 118 -132 137 -132 8
0 27 7 43 15 49 25 112 43 200 55 101 14 208 -1 333 -46 l77 -28 56 55 c84 82
84 82 12 122 -68 38 -168 71 -262 87 -58 10 -215 12 -266 3z"/>
<path d="M2698 6903 c-152 -165 -235 -394 -225 -618 8 -161 22 -225 79 -352
20 -44 89 -156 107 -174 9 -9 26 -28 38 -43 13 -14 29 -26 36 -26 15 0 137
118 137 132 0 8 -52 80 -70 98 -21 21 -61 98 -86 165 -27 75 -29 89 -29 225 1
186 26 263 132 407 29 40 53 76 53 81 0 12 -122 132 -135 132 -6 0 -23 -12
-37 -27z"/>
<path d="M3925 6886 c-22 -22 -50 -52 -62 -67 l-23 -26 52 -64 c53 -66 94
-145 123 -239 35 -110 18 -387 -26 -435 -5 -5 -9 -15 -9 -22 0 -17 -63 -117
-101 -161 -16 -18 -29 -41 -29 -50 0 -22 110 -132 131 -132 39 0 147 157 207
300 13 33 38 122 52 190 19 89 -2 356 -31 385 -5 5 -9 17 -9 27 0 10 -4 26 -9
36 -5 9 -24 46 -41 82 -56 111 -141 221 -169 219 -9 -1 -34 -20 -56 -43z"/>
<path d="M2972 6624 c-32 -39 -73 -117 -93 -179 -18 -53 -18 -230 0 -275 50
-129 112 -218 141 -204 8 4 41 35 73 69 l58 63 -29 41 c-41 57 -66 159 -52
214 15 58 43 127 52 127 4 0 12 9 18 20 10 18 3 29 -56 90 -37 39 -71 70 -75
70 -4 0 -21 -16 -37 -36z"/>
<path d="M3638 6594 c-71 -70 -78 -83 -53 -107 39 -39 60 -101 60 -177 0 -75
-13 -114 -63 -193 l-17 -27 67 -65 c38 -36 72 -65 77 -65 25 0 101 130 132
225 7 22 12 78 12 125 0 92 -9 132 -55 227 -23 49 -79 123 -91 123 -1 0 -32
-30 -69 -66z"/>
<path d="M3320 6403 c-40 -16 -60 -46 -60 -92 0 -51 4 -60 40 -84 40 -28 96
-22 132 13 27 28 30 36 25 76 -3 29 -13 53 -27 66 -22 20 -83 32 -110 21z"/>
<path d="M5213 5763 l-63 -4 0 -34 c0 -30 4 -34 43 -47 80 -25 77 0 77 -676 0
-663 2 -647 -67 -679 -32 -15 -38 -23 -38 -48 l0 -30 263 -3 262 -2 0 33 c0
28 -5 36 -32 48 -17 8 -41 27 -52 42 -20 27 -21 41 -21 341 l0 312 27 41 c34
52 88 85 148 91 60 6 102 -15 126 -65 17 -34 19 -68 22 -363 2 -314 2 -326
-18 -359 -12 -18 -34 -38 -50 -44 -25 -9 -30 -17 -30 -44 l0 -33 260 0 260 0
0 34 c0 30 -5 37 -37 53 -65 31 -66 38 -73 428 -7 378 -9 394 -66 472 -33 44
-120 84 -197 90 -110 9 -209 -27 -323 -118 -20 -16 -41 -29 -45 -29 -5 0 -9
135 -9 300 l0 300 -152 -2 c-84 -1 -181 -3 -215 -5z"/>
<path d="M8958 5763 l-68 -4 0 -34 c0 -19 5 -35 11 -35 6 0 26 -7 44 -16 67
-31 65 -8 65 -757 l0 -674 53 -7 c81 -9 92 -8 123 19 17 16 37 24 49 21 11 -2
56 -13 99 -25 88 -23 253 -29 345 -11 228 43 351 235 351 548 0 330 -111 502
-341 528 -114 13 -227 -33 -344 -139 -20 -18 -20 -17 -23 287 l-2 306 -148 -2
c-81 -1 -177 -3 -214 -5z m658 -643 c60 -57 83 -139 91 -317 7 -162 -6 -270
-41 -348 -53 -117 -143 -155 -246 -102 -35 17 -51 34 -72 76 l-28 55 0 258 c0
281 3 301 56 349 52 48 84 60 151 56 50 -2 68 -8 89 -27z"/>
<path d="M6930 5711 c-188 -28 -325 -114 -383 -239 -19 -40 -22 -65 -22 -162
0 -112 1 -117 34 -178 57 -105 131 -168 308 -263 174 -93 232 -146 258 -233
19 -63 19 -103 0 -157 -32 -89 -99 -129 -217 -131 -82 -1 -136 17 -181 60 -35
33 -73 105 -82 155 l-7 38 -72 -3 -71 -3 -3 -158 -3 -157 28 -11 c51 -20 247
-40 388 -41 154 -1 223 12 323 59 239 113 294 452 106 653 -46 49 -145 116
-299 201 -101 56 -189 140 -204 194 -20 72 -5 151 36 197 48 53 146 77 238 58
71 -15 133 -71 159 -146 28 -78 25 -76 99 -79 l67 -2 0 156 0 157 -102 17
c-121 20 -320 29 -398 18z"/>
<path d="M870 5667 c0 -29 5 -35 36 -46 20 -7 43 -26 55 -44 18 -31 19 -57 19
-600 0 -453 -3 -574 -13 -600 -13 -30 -35 -47 -79 -61 -13 -4 -18 -16 -18 -41
l0 -35 285 0 285 0 0 33 c0 31 -4 35 -38 45 -21 7 -47 21 -56 31 -26 29 -37
147 -34 366 l3 190 97 0 c121 0 144 -13 173 -101 11 -32 20 -60 21 -62 1 -1
27 -4 58 -6 l56 -3 0 234 0 233 -60 0 c-55 0 -60 -2 -60 -22 -1 -32 -33 -99
-60 -123 -19 -17 -36 -20 -124 -20 l-101 0 0 270 0 270 123 3 c69 1 141 -2
165 -9 54 -14 98 -62 130 -141 l25 -63 71 0 72 0 2 168 2 167 -517 0 -518 0 0
-33z"/>
<path d="M2962 5637 c-34 -34 -62 -68 -62 -74 0 -23 179 -100 270 -118 93 -17
208 -23 282 -15 129 15 279 66 346 118 l23 18 -66 67 -67 66 -66 -26 c-130
-49 -258 -64 -378 -42 -65 11 -173 46 -188 60 -18 17 -32 9 -94 -54z"/>
<path d="M2680 5307 c-73 -21 -135 -57 -188 -108 -28 -27 -54 -49 -58 -49 -4
0 -4 36 -1 80 l5 80 -161 0 c-89 0 -186 -3 -214 -6 -52 -7 -53 -7 -53 -40 0
-28 4 -33 29 -39 39 -8 77 -48 85 -86 3 -18 6 -186 6 -375 0 -389 -3 -406 -67
-440 -20 -10 -35 -22 -34 -26 0 -5 1 -19 1 -33 l0 -25 259 0 259 0 4 31 c5 29
2 33 -32 46 -21 8 -45 28 -56 46 -18 30 -19 54 -19 343 l0 310 28 43 c47 70
111 97 170 70 25 -11 77 -83 77 -106 0 -11 21 -13 98 -11 l97 3 3 139 c1 77
-1 143 -6 148 -16 16 -183 19 -232 5z"/>
<path d="M3401 5305 c-96 -22 -169 -61 -236 -129 -81 -81 -120 -164 -142 -296
-38 -232 30 -466 165 -565 163 -120 470 -122 644 -4 59 40 138 111 138 123 0
7 -17 29 -39 50 l-38 37 -67 -50 c-99 -75 -201 -98 -314 -69 -63 16 -133 85
-156 156 -17 51 -36 198 -27 207 2 3 155 5 339 5 l335 0 -5 93 c-6 106 -42
225 -88 292 -38 56 -113 110 -187 136 -77 27 -234 34 -322 14z m172 -109 c18
-7 40 -22 50 -33 27 -31 54 -120 62 -205 l7 -78 -181 0 c-209 0 -192 -12 -165
117 17 86 40 137 76 170 45 42 97 52 151 29z"/>
<path d="M4515 5310 c-209 -37 -319 -142 -320 -305 0 -139 68 -213 300 -328
158 -78 189 -103 203 -160 21 -88 -16 -155 -99 -177 -115 -31 -235 33 -274
146 l-13 39 -71 3 -71 3 0 -130 0 -129 48 -11 c242 -54 494 -47 622 19 129 66
189 219 144 370 -12 41 -29 66 -72 109 -57 56 -89 75 -257 151 -128 58 -165
99 -165 181 0 82 85 131 198 114 38 -5 55 -16 89 -50 22 -24 47 -60 54 -79
l13 -36 73 0 74 0 -3 122 -3 123 -65 12 c-85 16 -341 24 -405 13z"/>
<path d="M7600 5265 c0 -23 5 -35 14 -35 21 0 66 -40 78 -69 6 -15 13 -169 18
-371 6 -325 8 -348 29 -402 26 -68 93 -132 154 -149 23 -6 82 -9 131 -7 107 4
163 27 264 108 34 27 64 50 67 50 3 0 5 -37 5 -82 l0 -81 152 6 c83 4 175 7
205 7 l53 0 0 33 c0 30 -4 36 -39 49 -78 31 -76 15 -79 525 l-3 453 -205 0
-204 0 0 -32 c0 -28 6 -36 39 -51 64 -29 66 -38 69 -362 3 -319 0 -342 -58
-402 -89 -91 -208 -83 -253 15 -21 45 -22 62 -25 440 l-3 392 -205 0 -204 0 0
-35z"/>
<path d="M6875 4143 c-74 -27 -110 -57 -139 -115 -22 -44 -26 -65 -26 -137 1
-169 69 -246 215 -245 73 0 123 19 173 62 20 17 22 24 12 41 -14 26 -24 26
-61 0 -73 -52 -156 -27 -179 55 -17 62 -9 210 14 254 33 63 95 64 120 2 14
-35 18 -38 61 -42 26 -2 48 -1 51 1 2 2 4 28 4 58 0 49 -2 54 -26 63 -53 21
-166 22 -219 3z"/>
<path d="M7343 4146 c-96 -31 -145 -100 -157 -218 -10 -90 12 -166 62 -219 91
-96 309 -83 379 23 37 55 46 89 46 173 1 118 -33 184 -116 227 -50 25 -158 33
-214 14z m112 -46 c45 -17 65 -78 65 -202 0 -130 -16 -181 -62 -197 -39 -14
-78 3 -97 43 -20 43 -31 165 -21 236 11 81 31 110 87 129 1 1 13 -3 28 -9z"/>
<path d="M8055 4146 c-16 -7 -45 -25 -62 -40 -35 -29 -36 -28 -33 17 l2 27
-101 0 c-86 0 -101 -2 -101 -16 0 -9 9 -18 20 -21 32 -9 42 -71 39 -240 -4
-141 -6 -153 -26 -175 -41 -44 -32 -48 97 -48 95 0 120 3 120 14 0 7 -10 20
-22 28 -22 15 -23 23 -26 158 -4 151 4 189 42 215 32 23 73 19 92 -10 13 -19
16 -55 17 -179 2 -147 1 -155 -20 -178 -41 -43 -32 -48 91 -48 70 0 117 4 121
11 4 5 -5 21 -19 34 -26 24 -26 25 -26 175 l0 152 30 29 c23 24 37 30 62 27
51 -5 58 -30 58 -211 0 -145 -2 -159 -20 -174 -42 -36 -27 -43 100 -43 95 0
120 3 120 14 0 7 -10 20 -22 28 -22 15 -23 23 -28 194 -6 213 -15 239 -92 262
-60 19 -106 10 -163 -33 -54 -41 -59 -42 -72 -12 -22 49 -115 71 -178 43z"/>
<path d="M9205 4136 c-27 -12 -56 -30 -64 -39 -22 -27 -32 -20 -25 18 l6 35
-101 0 c-79 0 -101 -3 -101 -14 0 -7 11 -21 25 -30 l25 -16 0 -190 c0 -190 0
-190 -25 -212 -13 -11 -22 -25 -19 -30 3 -4 59 -8 125 -8 128 0 138 5 94 46
-25 23 -25 26 -25 174 l0 152 29 29 c39 38 84 40 108 3 12 -20 18 -55 21 -139
5 -137 -3 -201 -29 -223 -41 -35 -25 -42 101 -42 101 0 120 2 120 15 0 9 -6
18 -14 21 -26 10 -30 37 -36 214 -6 195 -13 220 -77 246 -46 20 -81 17 -138
-10z"/>
<path d="M9672 4141 c-67 -23 -97 -51 -112 -106 -17 -59 -5 -119 32 -160 l29
-34 -30 -38 c-40 -51 -47 -83 -26 -128 17 -35 17 -37 -2 -58 -69 -75 -43 -150
61 -178 22 -6 91 -9 156 -7 105 3 119 6 162 31 56 33 81 75 82 137 0 97 -53
129 -209 130 -143 0 -168 11 -144 63 9 20 17 22 97 22 62 1 96 6 118 17 78 42
112 132 81 216 l-15 44 41 -4 c40 -3 42 -2 42 26 1 15 -4 30 -10 31 -5 2 -75
6 -155 9 -124 4 -153 3 -198 -13z m147 -58 c21 -22 27 -148 9 -183 -19 -35
-60 -48 -91 -27 -51 33 -55 180 -6 220 25 20 61 16 88 -10z m65 -482 c19 -16
21 -59 3 -82 -22 -28 -54 -39 -119 -39 -109 0 -158 52 -112 119 16 22 21 23
114 19 62 -2 104 -9 114 -17z"/>
<path d="M6470 3725 l0 -76 68 3 67 3 3 73 3 72 -71 0 -70 0 0 -75z"/>
<path d="M8697 3793 c-3 -5 -4 -37 -3 -73 l1 -65 68 -3 67 -3 0 76 0 75 -64 0
c-36 0 -67 -3 -69 -7z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
